<template>
	<page-container :page="page">
		<header>
			<div v-if="page.headingImage" class="hero-image fullscreen">
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<div class="content">
						<span class="subtitle">{{ defaults[locale].homepage.headingSubTitle }}</span>
						<span>{{ defaults[locale].homepage.headingTitle }}</span>
					</div>
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</div>
		</header>

		<booking-form :booking-form="defaults[locale].website" :items="hotelsData" />

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #content>
				<div class="columns column7 align-left">
					<div class="title-reverse-wrapper">
						<h1>{{ page.title || page.header }}</h1>
						<h4 v-if="page.subtitle" class="subtitle">
							{{ page.subtitle }}
						</h4>
					</div>
					<div v-parse-links v-html="page.content" />
				</div>
				<div class="columns column5 intro-images">
					<div v-for="image in defaults[locale].homepage.images" :key="image.ID">
						<picture>
							<source v-if="image.imageWebp" :srcset="image.imageWebp" type="image/webp" />
							<source :srcset="image.image" />
							<img loading="lazy" :src="image.image" :alt="image.imageAlt" />
						</picture>
					</div>
				</div>
			</template>
		</main-content>

		<hotels
			:items="hotelsData"
			:title="defaults[locale].homepage.sectionHotelsTitle"
			:content="defaults[locale].homepage.sectionHotelsContent"
		/>

		<contentblocks
			v-if="page.contentblocks && page.contentblocks.length"
			:title="page.sectionContentblocksTitle"
			:content="page.sectionContentblocksContent"
			:items="page.contentblocks"
		/>

		<promoblocks :items="promoblocksData" :amount="3">
			<template #header>
				<div class="columns column12 header">
					<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
				</div>
			</template>
		</promoblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>
		<pre-footer v-if="page.footerSection && page.footerSection.length" :footer-section="page.footerSection[0]" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: hotelsData } = await useWebsiteFetch('hotels', {
	query: { language: locale.value },
	key: `${locale.value}/hotels`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 55%;
	transform: translate(0, -50%);
	left: 0;
	right: 0;
	z-index: 4;

	.content {
		color: #fff;
		text-align: center;
		width: 660px;
		max-width: 80%;
		margin: 0 auto;
		animation: fade-in 1500ms ease-out;
		font-family: lust-display, serif;

		span {
			font-size: 3.2em;
			line-height: 60px;
			color: #fff;
			font-weight: 400;
			display: block;
			width: 100%;

			&.subtitle {
				font-weight: 400;
				font-size: 20px;
				line-height: 100%;
				letter-spacing: 0.15em;
				text-transform: uppercase;
				color: #fff;
				padding-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}
}

.hero-image {
	position: relative;

	&.fullscreen {
		width: 100%;
		height: 100vh;
	}

	.slider-overlay {
		content: '';
		display: block;
		z-index: 1;
		position: absolute;
		inset: 0;
		background: rgb(0 0 0 / 15%);
	}
}

.header {
	h2 {
		color: #fff;
	}
}

.intro-images {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-around;

	div {
		width: 46%;
		margin: 0 0 4%;

		&:first-child {
			width: 96%;
		}
	}
}

.main-content.intro {
	padding: 40px 0 60px;
	background: url('~/assets/images/index-intro-bg-image.png') no-repeat bottom left;
}

@media (min-width: 980px) {
	.intro .column7 {
		padding-right: 220px;
	}
}

@media (max-width: 900px) {
	.hero-content {
		.content {
			span {
				font-size: 3.4em;

				&.subtitle {
					font-size: 18px;
				}
			}
		}
	}
}

@media (max-width: 680px) {
	.hero-content {
		.content {
			max-width: 92%;

			span {
				font-size: 2.8em;

				&.subtitle {
					font-size: 16px;
				}
			}
		}
	}
}

@media (max-width: 520px) {
	.hero-content {
		.content {
			max-width: 96%;

			span {
				font-size: 2.2em;

				&.subtitle {
					font-size: 15px;
				}
			}
		}
	}
}
</style>
